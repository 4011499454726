import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

import Carousel3d from "vue3-carousel-3d";

import "vue3-carousel-3d/dist/index.css";

import "fullpage.js";
import VueFullPage from "vue-fullpage.js";
import "fullpage.js/dist/fullpage.min.css"; // 引入fullpage.js的样式文件

import i18n from "@/utils/lang";

createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus)
  .use(Carousel3d)
  .use(VueFullPage)
  .use(i18n)
  .mount("#app");
