export default {
  // 导航栏
  nav: {
    home: "首页",
    functiona: "功能",
    introduce: "平台介绍",
    msg: "资讯",
    wx: "小程序",
  },
  proportion: {
    title: "",
    moneycar: "",
    trucks: "",
    carriage: "Coach & Bus",
    proportion: "",
  },
  carousel: {
    functions: "功能",
    search: "查车价",
    line: "——",
    car: "新车",
    num: "覆盖81000多种商用车车型",
    configuration: "车辆主要核心配置",
    price: "厂家指导价&近期成交价",
    dataServices: "扫描体验大数据服务",
    vehicleIndustry: "商用车行业的数据引擎",
  },
  carousel1: {
    search: "查销量",
    num: "近15年,5500万辆数据实时查询",
    configuration: "⼚商，区域，类型等多维度销量数据分析",
    price: "数据每月更新，实时掌握最新市场动向",
  },
  carousel2: {
    search: "主体信息",
    num: "覆盖81000多种商用车车型近100万+商⽤⻋⾏业主体机构信息",
    configuration:
      "企业⼯商信息，独家⾏业信息，商⽤⻋⾏业⿊名单共享平台，⾏业奖惩信息等",
  },
  carousel3: {
    search: "快速估值",
    line: "——",
    car: "二手商用车",
    num: "⼆⼿商⽤⻋线上快速估值",
    configuration: "全线上化操作",
    price: "⾏驶证扫描",
  },
  carousel4: {
    search: "登记状态",
    num: "商⽤⻋⾦融⻋辆抵质押信息共享平台，有效降低⾦融⾏业风险问题，解决抵质办理与查询难题",
  },
  footer: {
    Partner: "合作伙伴",
    company: "单位名称：商用车大数据平台",
    Contact: "联系电话：010-5338 8968",
    mail: "邮箱：service{'@'}chinaucv.com",
    address: "公司地址：北京市海淀区西三环北路72号院",
    Postcode: "邮编：100037",
    WeChat: "微信小程序",
    Scanning: "扫一扫体验",
    link: "友情链接：中国汽车流通协会",
    car: "copyright 2021{'@'}商用车大数据平台",
  },
  center: {
    title: sessionStorage.getItem("chineseTitle"),
    vehicle: "商用车",
    van: "货车",
    bus: "客车",
  },
  hov: {
    Scanning: "扫描二维码",
    test: "体验大数据服务",
  },
};
