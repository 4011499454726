export default {
  // 导航栏
  nav: {
    home: "HomePage",
    functiona: "Function",
    introduce: "Platform Introduction",
    msg: "News & Information",
    wx: "MINI PROGRAM",
  },
  hov: {
    Scanning: "Scan to Explore",
    test: "Big Data Galore",
  },
  proportion: {
    title: "",
    moneycar: "",
    trucks: "",
    carriage: "Coach & Bus",
    proportion: "",
  },
  carousel: {
    functions: "FUNCTION",
    search: "Price Search",
    line: "——",
    car: "New commercial vehicle",
    num: "Full coverage of over 81,000 kinds of models of commercial vehicles",
    configuration: "Core configuration of the vehicle",
    price: "Guide price from manufacturers & Recent transaction price",
    dataServices: "Scan and experience big data services",
    vehicleIndustry: "Data engine for commercial vehicle industry",
  },
  carousel1: {
    search: "Historical sales volumes search",
    num: "Quick search the data of 55 million vehicles within the past 15 years",
    configuration:
      "Multi- dimensional sales data analysis from manufacturer, region, type, etc.",
    price: "Monthly data update & hunting the latest market trends timely",
  },
  carousel2: {
    search: "Corporate information Search",
    num: "Over 1 million corporate information of commercial vehicle industry",
    configuration:
      "Enterprise information, Exclusive industry information, Sharing platform of industry blacklist, Industry reward and punishment information, etc.",
  },
  carousel3: {
    search: "Quick valuation for ",
    line: "——",
    car: "Used Commercial Vehicle",
    num: "Online quick valuation for used commercial vehicles",
    configuration: "Online operation",
    price: "Driving license Scan",
  },
  carousel4: {
    search: "Vehicle Registration Status Search",
    num: "Sharing platform to grasp the mortgage status of commercial vehicle and then effectively lower the financial industry risk and solve the problem of mortgage and query",
  },
  footer: {
    Partner: "PARTNER",
    company: "Company: Big Data Platform For Commercial Vehicle Industry",
    Contact: "Contact : 010-5338 8968",
    mail: "E-mail: service@chinaucv.com",
    address:
      "Address: Courtyard 72, West Third Ring North Road, Haidian District, Beijing",
    Postcode: "Postcode: 100037",
    WeChat: "WeChat Program",
    Scanning: "Scanning to experience",
    link: "links: China Automobile Dealers Association",
    car: "copyright 2021 @ BIG DATA Platform FOR COMMERCIAL VEHICLE industry",
  },
  center: {
    title: sessionStorage.getItem("englishTitle"),
    vehicle: "Commercial vehicle",
    van: "Truck",
    bus: "Bus",
  },
};
